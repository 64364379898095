/* .App {
  font-family: sans-serif;
  text-align: center;
} */

.main {
  margin:'auto';
  width:'50%';
  background-color: blue;
}

.mainOuter {
  display: table;
  width: 100%;
  height: 10rem;
  text-align: center;
  margin: 5% 0;

}

.mainInner{
  display: table-cell;
  vertical-align: middle;
}
